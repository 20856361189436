.about-company-wrap {
  @include section-wrap;
  text-align: left;
  margin-top: 60px;

  .about-company-article {
    > ul {
      margin-left: 8%;
    }
  }

  .about-logo {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    
    img {
      // max-width: 300px;
      width: 15%;
    }
  
    @media screen and (max-width: $tablet) {
      img {
        width: 30%;
      }
    }
  }
}