.header {
  background-image: url("../static/main-backgroud.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  object-fit: cover;
  // height: 50vh;
  border-bottom: 3px solid #2e2e2e;

  .header-wrap {
    @include section-wrap;
  }

  .logo-wrap {
    display: flex;
    align-items: center;

    .header-container {
      display: flex;
      align-items: center;
      margin-top: 20px;

      h1 {
        padding-left: 40px;
        font-size: 60px;
        color: $text-color-2;
      }
  
      .logo {
        width: 200px;
      }
    }

    .header-links {
      margin-left: auto;
      background: none;
    }
    
  }

  .intro-wrap {
    @include section-wrap;
    margin-top: 20px;
    margin-bottom: 180px;
    background-color: rgba(255, 255, 255, 0.6);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    // margin-top: 80px;

    .intro-section {
      @include section;
      display: flex;
    
      .intro {
        display: flex;
        justify-content: space-around;
        padding-right: 160px;
        font-size: $font-size-big;

        .intro-item {
          width: 30%;

          .intro-item-title {
            font-size: $font-size-big;
            text-transform: uppercase;
            color: $text-color-3;
            font-weight: bold;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  // .intro-wrap {
  //   background-color: rgba(255, 255, 255, 1);
  //   margin-bottom: 60px;
    
  //   .intro-section {
  //     display: flex;
  //     flex-direction: column;

  //     .intro {
  //       padding-right: 0;
  //     }
  //   }
  // }
}

@media screen and (max-width: $phone) {
  // .header {
  //   h1 {
  //     font-size: $font-size-h1-mob;
  //     font-weight: bold;
  //   }

  //   h2 {
  //     font-size: $font-size-h2-mob;
  //     font-weight: bold;
  //   }
    
  //   h3 {
  //     font-size: $font-size-h3-mob;
  //   }
  // }

  // .header-links {
  //   text-align: right;
  //   padding-right: $page-padding-mob;
  // }

  // .main {
  //   .header-wrap {
  //     padding: 0;
  //   }

  //   .logo-wrap {
  //     padding-left: 20px;

  //     h1 {
  //       padding-left: 20px;
  //       margin: 0;
  //     }

  //     .logo {
  //       width: 60px;
  //       height: 60px;
  //     }
  //   }
  // }

  // .intro-wrap {
  //   @include section-wrap-mob;
  // }
}
