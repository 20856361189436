@import 'variables';
@import 'header';
@import 'footer';
@import 'about-services';
@import 'about-company';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrap {
  display: flex;
  flex-direction: column;

  h1 {
    font-size: $font-size-h1;
  }
  
  h2 {
    font-size: $font-size-h2;
    color: $text-color-2;
  }
  
  h3 {
    font-size: $font-size-h3;
  }
}

