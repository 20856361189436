.about-wrap {
  margin-top: 60px;
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  @include section-wrap;

  .about-item-article {
    flex: 1;

    h2 {
      font-size: $font-size-big;
      text-transform: uppercase;
      color: $text-color-3;
      font-weight: bold;
    }

    .about-item {
      text-align: left;

      .about-description {
        min-width: 300px;
      }
      .about-description-img {
        height: fit-content;
        max-width: 100%;
      }
    }

    .about-services-list {
      display: flex;
    }
  }
}